import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./styles.module.css";

export default function Footer() {

  const email = 'kontakt@villaggio-kapfenstein.at';
  const phone = '+43 664 3711199';

  const broughttoyouEnabled = true;
  const broughttoyouLink = 'https://southeast.at';
  const broughttoyouName = 'southeast.at';

  return (
    <footer className={styles.footer}>
      <div className={styles.copywrightcontainer}>
        <span className={styles.copyright}>
          &#169; {new Date().getFullYear()} Pizzeria Ristorante Villaggio
        </span>
        {
          broughttoyouEnabled &&
          <>
            <span className={styles.sep}>
              |
            </span>
            <span className={styles.ref}>
              Brought to you by <a href={broughttoyouLink} className={styles.reflink} target="_blank">
                <strong>{broughttoyouName}</strong>
              </a>
            </span>
          </>
        }
      </div>
      <div className={styles.linkcontainer}>
        <div className={styles.leftside}>
          <Link to='/impressum' className={styles.links}>
            Impressum
          </Link>
          <div className={styles.links}>
            |
          </div>
          <Link to='/datenschutz' className={styles.links}>
            Datenschutzerklärung
          </Link>
        </div>
        <div className={styles.rightside}>
          <a href={'mailto:' + email} className={styles.links}>
            {email}
          </a>
          <a href={'tel:' + phone.replaceAll(' ', '')} className={styles.links}>
            Tel. {phone}
          </a>
        </div>
      </div>
    </footer>
  );
}
