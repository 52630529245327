import * as React from 'react';
import NewsPart from './NewsPart/NewsPart';
import * as styles from './styles.module.css';
import clsx from 'clsx';

import TouchMovement from './TouchMovement';
import useOnScreen from '../../../hooks/useOnScreen';

import barImage from '../../../images/news-section-bar-image.webp';
import iceImage from '../../../images/news-section-ice-image.webp';
import outsideImage from '../../../images/news-section-outside-image.webp';
import peopleImage from '../../../images/news-section-people-image.webp';
import ice2Image from '../../../images/news-section-ice2-image.webp';
import people2Image from '../../../images/news-section-people2-image.webp';
import outside2Image from '../../../images/news-section-outside2-image.webp';
import foodImage from '../../../images/news-section-food-image.webp';
import food2Image from '../../../images/news-section-food2-image.webp';
import food3Image from '../../../images/news-section-food3-image.webp';
import food4Image from '../../../images/news-section-food4-image.webp';

const news = [
  {
    title: "Jetzt neu! Eis🍦",
    type: "bottom",
    description: "Genießen Sie verschiedene köstliche Eissorten!",
    image: iceImage
  },
  {
    title: "Gemütliche Atmosphäre",
    type: "bottom",
    description: "Kommen Sie und genießen Sie Speis und Trank in unserem Lokal.",
    image: barImage
  },
  {
    title: "Pizzeria Ristorante Villaggio",
    type: "bottom",
    description: "Willkommen! Besuchen Sie uns in Kapfenstein und genießen Sie ausgewählte Gerichte.",
    image: outsideImage
  },
  {
    type: "nodescription",
    image: peopleImage
  },
  {
    type: "nodescription",
    image: ice2Image
  },
  {
    type: "nodescription",
    image: people2Image
  },
  {
    type: "nodescription",
    image: outside2Image
  },
  {
    type: "nodescription",
    image: foodImage
  },
  {
    type: "nodescription",
    image: food2Image
  },
  {
    type: "nodescription",
    image: food3Image
  },
  {
    type: "nodescription",
    image: food4Image
  }
];

export default function NewsSection() {

  const ref = React.useRef();
  const onScreen = useOnScreen(ref);

  const sliderRef = React.useRef();

  const [currentIndex, setIndex] = React.useState(0);
  const [currentInterval, updateInterval] = React.useState(null);

  const stopInterval = () => {
    if (currentInterval)
      clearInterval(currentInterval);
  };

  const setNews = (index) => {
    stopInterval();
    setIndex(index);
  };

  const move = (amount) => {
    stopInterval();
    moveNews(amount);
  };

  const moveNews = (amount) => {
    setIndex((oldIndex) => {
      let index = oldIndex + amount;
      if (index < 0)
        index = news.length - 1;
      if (index >= news.length)
        index = 0;
      return index;
    });
  }

  TouchMovement(sliderRef.current, (dir) => {
    if (dir === 'right')
      move(+1);
    if (dir === 'left')
      move(-1);
  });

  React.useEffect(() => {
    if (onScreen) {
      const interval = setInterval(() => {
        moveNews(1);
      }, 5500);
      updateInterval(interval);
    }
  }, [onScreen]);

  React.useEffect(() => {
    return stopInterval;
  }, [currentInterval]);

  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.section}>
        <div className={styles.heading}>
          <h1 className={styles.underline}>Aktuelles</h1>
        </div>
        <div className={styles.newscontainer}>
          <div ref={sliderRef}>
            {
              news.map((post, count) => {
                return <NewsPart key={count} props={post} visible={count === currentIndex}></NewsPart>
              })
            }
          </div>
          <div className={styles.controlcontainer}>
            <div className={styles.arrowcontainer} onClick={() => move(-1)}>
              <i className={clsx(styles.arrow, styles.leftarrow)}></i>
            </div>
            <div className={styles.circlecontainer}>
              {
                news.map((post, count) => {
                  return (
                    <div key={count}
                      className={clsx(styles.circle, count === currentIndex ? styles.circlehighlight : '')}
                      onClick={() => setNews(count)}
                    />
                  );
                })
              }
            </div>
            <div className={styles.arrowcontainer} onClick={() => move(+1)}>
              <i className={clsx(styles.arrow, styles.rightarrow)}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
