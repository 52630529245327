import * as React from "react";
import { Link } from "gatsby";

import PizzaImage from "../../../images/image-section-pizza.webp";
import BurgerImage from "../../../images/image-section-burger.webp";
import SeafoodImage from "../../../images/image-section-seafood.webp";
import SteakImage from "../../../images/image-section-steak.webp";

import foreignIcon from "../../../images/icons/foreign.png";

import * as styles from "./styles.module.css";

export default function ImageSection() {
  return (
    <>
      <div id='speisen' className={styles.fakeanchor}></div>
      <div className={styles.container}>
        <Link className={styles.imagecontainer} to='/speisekarte#2'>
          <div className={styles.overlay}>
            <h3>Burger</h3>
            <img src={foreignIcon} className={styles.linkicon} />
          </div>
          <img src={BurgerImage} alt='Burger' className={styles.image} />
        </Link>
        <Link className={styles.imagecontainer} to='/speisekarte#1'>
          <div className={styles.overlay}>
            <h3>Pizza</h3>
            <img src={foreignIcon} className={styles.linkicon} />
          </div>
          <img src={PizzaImage} alt='Pizza' className={styles.image} />
        </Link>
        <Link className={styles.imagecontainer} to='/speisekarte#3'>
          <div className={styles.overlay}>
            <h3>Meeresfrüchte</h3>
            <img src={foreignIcon} className={styles.linkicon} />
          </div>
          <img src={SeafoodImage} alt='Meeresfrüchte' className={styles.image} />
        </Link>
        <Link className={styles.imagecontainer} to='/speisekarte#all'>
          <div className={styles.overlay}>
            <h3>Und vieles mehr...</h3>
            <img src={foreignIcon} className={styles.linkicon} />
          </div>
          <img src={SteakImage} alt='Steak' className={styles.image} />
        </Link>
      </div>
    </>
  );
}
