import { Link } from "gatsby";
import * as React from "react";
import ButtonHover from "../../ui/ButtonHover/ButtonHover";
import * as styles from "./styles.module.css";

import LocationIcon from "../../../images/icons/location.svg";
import { animated, useSpring } from "react-spring";

export default function HeroSection() {
  return (
    <div className={styles.imagecontainer}>
      <div className={styles.placeholder}></div>
      <animated.div className={styles.section} style={useSpring({ to: { opacity: 1 }, from: { opacity: 0 }, delay: 200 })}>
        <div className={styles.centercontainer}>
          <div className={styles.leftside}>
            <div className={styles.blockcontainer}>
              <div className={styles.contentContainer}>
                <div className={styles.text}>
                  <span className={styles.topHeading}>Pizzeria Ristorante</span>
                  <h1 className={styles.heading}>Villaggio</h1>
                  <p>
                    Hausgemachte Pizzen, Pasta, Burger und vieles mehr. Entdecken Sie unser Angebot
                    oder bestellen Sie ganz bequem von Zuhause aus.
                  </p>
                </div>
                <div className={styles.callToActions}>
                  <ButtonHover href='#kontakt' size='LARGE'>
                    Kontakt
                  </ButtonHover>
                  <Link className={styles.locationLink} to='#standort'>
                    <img src={LocationIcon} alt='Location Icon' />
                    <span>Wo Sie uns finden</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rightside}>
            <div className={styles.timebox}>
              <table>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "right", fontWeight: 600 }}>Montag</td>
                    <td style={{ fontWeight: 600 }}>-</td>
                    <td style={{ textAlign: "left", fontWeight: 600 }}>Sonntag</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>11:00</td>
                    <td>-</td>
                    <td style={{ textAlign: "left" }}>22:00</td>
                  </tr>

                  <tr>
                    <td colspan="3" style={{ textAlign: "center", fontWeight: 600 }}>Zustellung:</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right", fontWeight: 600 }}>Freitag</td>
                    <td style={{ fontWeight: 600 }}>-</td>
                    <td style={{ textAlign: "left", fontWeight: 600 }}>Sonntag</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>11:45</td>
                    <td>-</td>
                    <td style={{ textAlign: "left" }}>20:00</td>
                  </tr>

                  {/* <tr>
                    <td style={{ textAlign: "right", fontWeight: 600 }}>Montag</td>
                    <td style={{ fontWeight: 600 }}>-</td>
                    <td style={{ textAlign: "left", fontWeight: 600 }}>Dienstag</td>
                  </tr>
                  <tr>
                    <td colspan="3" style={{ textAlign: "center" }}>Ruhetag</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </animated.div>
    </div>
  );
}
