import * as React from "react"
import clsx from "clsx"
import ButtonHover from "../../ui/ButtonHover/ButtonHover"
import * as styles from './styles.module.css'
import { Link } from "gatsby";

import phoneIcon from '../../../images/icons/phone.svg';

const navbarlinks = [
    {
        id: 0,
        name: "Speisen",
        url: "#speisen"
    },
    {
        id: 1,
        name: "Standort",
        url: "#standort"
    },
    {
        id: 2,
        name: "Kontakt",
        url: "#kontakt"
    }
];

export default function NavBar() {

    const [isMobileOpen, setIsMobileOpen] = React.useState(false);
    const [isTransparent, setTransparent] = React.useState(true);

    const setMobileMenuOpen = (state) => {
        setIsMobileOpen(state);
        document.body.style.overflow = state ? 'hidden' : '';
    }

    const scrollEventHandler = () => {
        const shouldBeWhite = window.scrollY > 70;
        if (shouldBeWhite && isTransparent) {
            setTransparent(false);
        } else if (!shouldBeWhite && !isTransparent) {
            setTransparent(true);
        }
    }

    React.useEffect(() => {
        scrollEventHandler();
        window.addEventListener('scroll', scrollEventHandler);
        return () => {
            window.removeEventListener('scroll', scrollEventHandler);
        }
    }, [isTransparent]);

    return (
        <>
            <header className={clsx(styles.header, (isTransparent && !isMobileOpen) ? styles.transparent : '', !isMobileOpen ? styles.transitions : '')}>
                <div className={styles.container}>
                    <div className={styles.leftcontainer}>
                        <div>
                            <img className={styles.logo} src='/icons/icon-144x144.png' alt="Logo" />
                        </div>
                        <Link className={styles.logotext} to='/' onClick={() => setIsMobileOpen(false)}>
                            Villaggio Kapfenstein
                        </Link>
                    </div>
                    <div className={styles.rightcontainer}>
                        <div className={styles.desktopcontainer}>
                            <div className={styles.links}>
                                {navbarlinks && navbarlinks.map((link) => (
                                    <div key={link.id}>
                                        <a className={clsx(styles.link, styles.underline)} href={link.url}>{link.name}</a>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <ButtonHover href="tel:+436643711199" size='SMALL' icon={phoneIcon}>Anrufen</ButtonHover>
                            </div>
                        </div>
                        <div className={styles.mobilecontainer}>
                            <div style={{ paddingRight: '15px', display: 'flex' }}>
                                <ButtonHover href="tel:+436643711199" size='SMALL'>Anrufen</ButtonHover>
                            </div>
                            <div className={isMobileOpen ? styles.extended : ''} onClick={() => setMobileMenuOpen(!isMobileOpen)}>
                                <div className={clsx(styles.bar, styles.bar1)}></div>
                                <div className={clsx(styles.bar, styles.bar2)}></div>
                                <div className={clsx(styles.bar, styles.bar3)}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {isMobileOpen &&
                <div className={styles.overlay}>
                    <div className={styles.mobilelinks}>
                        {navbarlinks && navbarlinks.map((link) => (
                            <a key={link.id} className={styles.mobilelink} href={link.url} onClick={() => setMobileMenuOpen(false)}>{link.name}</a>
                        ))}
                    </div>
                </div>
            }
        </>
    )
}
