import * as React from 'react'
import { Helmet } from 'react-helmet'

import titleimage from '../../images/metadata-image.jpg';

export default function Head() {

  const title = 'Pizzeria Ristorante Villaggio Kapfenstein';
  const description = 'Hausgemachte Pizzen, Pasta, Burger und vieles mehr. Entdecken Sie unser Angebot oder bestellen Sie ganz bequem von Zuhause aus.';
  const mainurl = 'https://www.villaggio-kapfenstein.at';
  const relFavicon = '/favicon-32x32.png';

  return (
    <Helmet
      htmlAttributes={{
        lang: 'de',
      }}>
      <meta charSet='utf-8' />
      <title>{title}</title>
      <meta name='description' property='og:description' content={description} />

      <meta charset="UTF-8" />
      <link rel="icon" type="image/png" href={mainurl + relFavicon} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content="pizza, villaggio, kapfenstein, pizza villaggio, pizza villaggio kapfenstein, kapfenstein pizza, villaggio kapfenstein, döner, döner kapfenstein, calamari, calamari kapfenstein" />
      <meta name="robots" content="all" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="de" />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={mainurl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={mainurl + titleimage} />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:url" content={mainurl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={mainurl + titleimage} />

      <link href={mainurl} rel="canonical" />

    </Helmet>
  )
}
